<template>
  <container>
    <PartHead />
    <main class="mt-20 lg:mt-0">
      <slot />
    </main>
    <PartFooter />
  </container>
</template>
<script setup>
const route = useRoute();
useHead(() => ({
  link: [
    {
      rel: "canonical",
      href: "https://www.getheltia.com" + route.path,
    },
  ],
}));
</script>
